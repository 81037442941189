import { TabItems } from "../common/components/Tabs";

export const tabItems: TabItems[] = [
    {
      title: "About",
      uId: "about",
    },
    {
      title: "Resume",
      uId: "resume",
    },
    // {
    //   title: "Project",
    //   uId: "project",
    // },
    {
      title: "Contact",
      uId: "contact",
    },
  ];
